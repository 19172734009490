<template>
  <main id="app">
    <page-nav-bar />
    <div class="page-content">
      <router-view/>
    </div>
  </main>
</template>

<script>
import PageNavBar from '@/components/PageNavBar'

export default {
  name: 'App',
  components: {
    PageNavBar
  },
  created () {
    this.$store.dispatch('cookie/setCurrency', localStorage.getItem('currency'))
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
