import global from '@/common/axios-global'

const ApiService = {
  get (url, params = {}, responseType = 'json') {
    setToken()
    return global.get(url, { params, responseType })
  },
  post (url, params = {}) {
    setToken()
    return global.post(url, params)
  },
  patch (url, params = {}) {
    setToken()
    const data = new FormData()

    Object.entries(params).forEach(([key, value]) => data.append(key, value))
    data.append('_method', 'PATCH')

    return global.post(url, data)
  }
}

function setToken () {
  if (localStorage.getItem('token_hashratecost')) {
    const token = localStorage.getItem('token_hashratecost')
    global.defaults.headers.common = { Authorization: `Bearer ${token}` }
  } else {
    delete global.defaults.headers.common.Authorization
  }
}

export default ApiService
