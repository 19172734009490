<template>
    <div class="apexchart">
      <div class="apexchart__control">
        <button
          class="apexchart__button"
          type="button"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
      <apexchart
        ref="chart"
        :options="chartOptions"
        :series="series"
        @markerClick="markerClick"
        @beforeZoom="setIntervalChart"
        @mounted="setIntervalChartMounted"
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AreaChart',
  props: {
    chart: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chartOptions: {
        chart: {
          type: 'area',
          zoom: {
            autoScaleYaxis: true
          },
          toolbar: {
            show: true,
            tools: {
              download: false
            }
          }
        },
        xaxis: {
          type: 'datetime',
          label: {
            show: true,
            text: 'Rally'
          },
          labels: {
            datetimeUTC: false
          },
          yAxisIndex: 0
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy'
          }
        },
        stroke: {
          curve: 'smooth'
        },
        dataLabels: {
          enabled: false
        },
        title: {
          text: '',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
            fontFamily: undefined,
            color: '#263238'
          }
        },
        subtitle: {
          text: '',
          align: 'left'
        }
      },
      series: [{
        name: '',
        data: []
      }],
      currentMinDate: '',
      currentMaxDate: ''
    }
  },
  computed: {
    ...mapGetters({
      currency: 'cookie/getCurrency'
    })
  },
  watch: {
    chart () {
      this.setCharts()
      this.$refs.chart.updateSeries([{
        data: this.series[0].data
      }], true)
    }
  },
  created () {
    this.setCharts()
  },
  methods: {
    setCharts () {
      this.series[0].data = []
      this.series[0].name = `${this.chart.currency || this.currency.toUpperCase()} per ${this.chart.TH || '100'}TH/s`
      this.chartOptions.subtitle.text = ''

      const objTemp = Object.keys(this.chart.data)
      const lastDate = objTemp[objTemp.length - 3]

      let minDate = new Date(lastDate)
      minDate = minDate.setDate(minDate.getDate() - 1)

      this.chartOptions.xaxis.min = new Date(minDate).getTime()
      this.chartOptions.title.text = this.chart.title

      const arrChartValues = Object.values(this.chart.data)
      if (arrChartValues.length && parseFloat(arrChartValues[arrChartValues.length - 1])) {
        this.chartOptions.title.text += ` ${this.chart.TH || '100'} TH/s = ${parseFloat(arrChartValues[arrChartValues.length - 1]).toFixed(3)} ${this.chart.currency || this.currency.toUpperCase()}`
      } else {
        this.chartOptions.title.text += ' No Data'
      }

      Object.entries(this.chart.data).forEach(([date, value]) => {
        if (!value) return
        this.series[0].data.push([this.formatDate(date), value])
      })
    },
    markerClick (event, chartContext, { seriesIndex, dataPointIndex, config }) {
      this.$emit('markerClick', chartContext.opts.series[0].data[dataPointIndex])
    },
    formatDate (date) {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      return isSafari ? new Date((date).replace(/\s/, 'T') + 'Z') : new Date(date)
    },
    setIntervalChartMounted (ctx, { config }) {
      if (config.xaxis.min) {
        this.currentMinDate = new Date(config.xaxis.min)
      }

      if (config.xaxis.max) {
        this.currentMaxDate = new Date(config.xaxis.max)
      }
    },
    setIntervalChart (ctx, { xaxis }) {
      this.currentMinDate = new Date(xaxis.min)
      this.currentMaxDate = new Date(xaxis.max)
    },
    downloadCSV () {
      const rows = this.series[0].data
        .filter(([date, value]) => {
          return (this.currentMinDate <= new Date(date)) && ((this.currentMaxDate >= new Date(date)) || !this.currentMaxDate)
        })
        .map(([date, value]) => {
          return [formatDate(date), value]
        })

      function convertToCsv (fName, rows) {
        var csv = ''
        for (var i = 0; i < rows.length; i++) {
          var row = rows[i]
          for (var j = 0; j < row.length; j++) {
            var val = row[j] === null ? '' : row[j].toString()
            val = val.replace(/\t/gi, ' ')
            if (j > 0) { csv += '\t' }
            csv += val
          }
          csv += '\n'
        }

        // for UTF-16
        var cCode; var bArr = []
        bArr.push(255, 254)
        for (let i = 0; i < csv.length; ++i) {
          cCode = csv.charCodeAt(i)
          bArr.push(cCode & 0xff)
          bArr.push(cCode / 256 >>> 0)
        }

        var blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' })
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, fName)
        } else {
          var link = document.createElement('a')
          if (link.download !== undefined) {
            var url = window.URL.createObjectURL(blob)
            link.setAttribute('href', url)
            link.setAttribute('download', fName)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
        }
      }
      function formatDate (date) {
        const d = new Date(date)
        let month = (d.getMonth() + 1).toString()
        let day = d.getDate().toString()
        const year = d.getFullYear()
        let minute = d.getMinutes().toString()
        let hour = d.getHours().toString()

        if (month.length < 2) { month = '0' + month }
        if (day.length < 2) { day = '0' + day }
        if (minute.length < 2) { minute = '0' + minute }
        if (hour.length < 2) { hour = '0' + hour }

        return `${day}.${month}.${year} ${hour}:${minute}`
      }

      convertToCsv('file', rows)
    }
  }
}
</script>

<style lang="scss">
.apexchart {
  display: flex;
  flex-direction: column;

  &__control {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__button {
    font-size: 12px;
    background-color: transparent;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 4px;
    outline: none;

    &:active {
      box-shadow: inset 2px 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
