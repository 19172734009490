import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Admin from '../views/Admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    title: 'Главная'
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    title: 'Админ панель'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Admin' && !localStorage.getItem('token_hashratecost')) {
    next({ name: 'Home', params: { unauthorized: true } })
  }

  next()
})

export default router
