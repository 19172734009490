<template>
  <div class="load">
    <b-spinner label="spinning" class="load__spinner" />
  </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="scss">
.load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  &__spinner {
    // Что бы перебить стили bootstrap
    width: 3rem !important;
    height: 3rem !important;
  }
}
</style>
