<template>
  <div class="edit-chart-panel">
    <input
      v-model="date"
      class="form-control"
      type="datetime-local"
      :disabled="changeValue.id"
    >
    <input
      v-model="value"
      class="form-control"
      type="number"
    >
    <b-button
      variant="primary"
      :disabled="isDisabled"
      @click="addNewValue"
    >
      {{ changeValue.id ? 'Изменить' : 'Добавить' }}
    </b-button>
    <b-button
      variant="danger"
      @click="clearValue"
    >
      Очистить
    </b-button>
    <b-button
      variant="outline-danger"
      :disabled="!changeValue.id"
      @click="deleteValue"
    >
      Удалить
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'EditChartControl',
  props: {
    changeValue: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      date: '',
      value: ''
    }
  },
  computed: {
    isDisabled () {
      return !this.value || !this.date
    }
  },
  watch: {
    changeValue (newValue) {
      this.date = newValue.date || ''
      this.value = newValue.value
    }
  },
  methods: {
    addNewValue () {
      if (!this.value && !this.date) return

      const value = parseFloat(this.value).toFixed(3)
      if (this.changeValue.id) {
        this.$emit('changeValue', { value: value, id: this.changeValue.id })
      } else {
        this.$emit('addNewValue', { date: this.date, value: value })
      }

      this.clearValue()
    },
    deleteValue () {
      this.$emit('deleteValue', this.changeValue.id)
      this.clearValue()
    },
    clearValue () {
      this.date = ''
      this.value = ''
      this.$emit('clearValue')
    }
  }
}
</script>

<style lang="scss">
.edit-chart-panel {
  display: grid;
  grid-template-columns: 1fr 80px 1fr 1fr 1fr;
  grid-gap: 4px;

  @media (max-width: 670px) {
    grid-template-columns: 1fr;
  }
}
</style>
