<template>
<div class="edit-chart">
  <edit-chart-control
    class="mb-2"
    :changeValue="findItem"
    @clearValue="findItem = {}"
    @addNewValue="addNewValue"
    @changeValue="changeValue"
    @deleteValue="deleteValue"
  />
  <span
    v-if="error"
    class="text-danger">
    {{ error }}
  </span>
  <area-chart
    :chart="formatDataChart"
    @markerClick="markerClick"
  />
</div>
</template>

<script>
import EditChartControl from '@/components/EditChartTemp/components/EditChartControl'
import AreaChart from '@/components/AreaChart'
import ApiService from '@/common/api.service'

export default {
  name: 'EditChartTemp',
  components: {
    AreaChart,
    EditChartControl
  },
  props: {
    chartData: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      findItem: {},
      error: ''
    }
  },
  computed: {
    formatDataChart () {
      const chartData = { title: 'Custom Chart', data: {}, TH: '1', currency: 'USD' }

      this.chartData.forEach(item => {
        chartData.data[item.date] = parseFloat(item.value)
      })

      return chartData
    }
  },
  methods: {
    markerClick ([date, value]) {
      this.findItem = this.chartData.find(item => {
        return (new Date(item.date).getDate() === new Date(date).getDate()) &&
          (new Date(item.date).getFullYear() === new Date(date).getFullYear()) &&
          (new Date(item.date).getMonth() === new Date(date).getMonth()) &&
          (new Date(item.date).getHours() === new Date(date).getHours()) &&
          (new Date(item.date).getMinutes() === new Date(date).getMinutes())
      })
    },
    addNewValue (data) {
      ApiService.post('admin_board/data', data)
        .then(({ data }) => {
          if (data.error) {
            this.error = data.message
            setTimeout(() => { this.error = '' }, 3000)
            return
          }
          this.$emit('update')
        })
        .catch(err => console.log(err))
    },
    changeValue ({ value, id }) {
      ApiService.patch(`admin_board/data/${id}`, { value: value })
        .then(() => {
          this.$emit('update')
        })
        .catch(err => console.log(err))
    },
    deleteValue (id) {
      ApiService.post(`admin_board/data/${id}/delete`)
        .then(() => {
          this.$emit('update')
        })
        .catch(err => console.error(err))
    }
  }
}
</script>
