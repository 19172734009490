<template>
  <b-navbar sticky toggleable="lg" type="dark" variant="dark">

      <router-link to="/">
        <b-navbar-brand>
          Hashratecost
        </b-navbar-brand>
      </router-link>

    <b-navbar-nav class="ml-auto">
      <b-nav-item v-if="$route.name === 'Admin'" @click="openModalCoinDesk">
        CoinDesk
      </b-nav-item>

      <b-nav-item v-if="$route.name === 'Admin'" @click="logout">
        <svg width="20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-sign-out-alt fa-w-16 fa-3x"><path fill="#fff" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z" class=""></path>
        </svg>
      </b-nav-item>

      <div
        v-if="$route.name !== 'Admin'"
        v-on-clickaway="closeCurrencyDropdown"
        class="header-lang"
        @click="toggleCurrencyDropdown"
      >
        <b-nav-item>
          <span>
            {{ currentCurrency.toUpperCase() }}
          </span>
        </b-nav-item>

        <transition name="fade">
          <ul
            v-if="isOpenCurrencyDropdown"
            class="header-lang__dropdown"
          >
            <li
              v-for="(currency, index) in filteredCurrency"
              :key="index"
              class="header-lang__dropdown-item"
              @click="changeCurrency(currency)"
            >
                <span>
                  {{ currency }}
                </span>
            </li>
          </ul>
        </transition>
      </div>
    </b-navbar-nav>

    <b-modal
      id="bv-modal-coindesk"
      hide-footer
      @cancel="closeModalCoinDesk"
      @close="closeModalCoinDesk"
    >
      <template #modal-title>
        CoinDesk Отчет
      </template>

      <b-form @submit.prevent="onSubmitCoinDesk">
        <b-form-group
          id="input-group-1"
          label="Начало периода"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="coindesk.date_start"
            type="date"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Конец периода"
          label-for="input-2"
        >
          <b-form-input
            id="input-2"
            v-model="coindesk.date_end"
            type="date"
            required
          ></b-form-input>
        </b-form-group>

        <div class="d-flex align-items-center justify-content-between">
          <b-button
            type="submit"
            variant="primary"
          >
            Получить отчет
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      id="bv-modal-auth"
      hide-footer
      @cancel="closeModalAuth"
      @close="closeModalAuth"
    >
      <template #modal-title>
        Авторизация
      </template>

      <b-form @submit.prevent="onSubmit">
        <b-form-group
          id="input-group-1"
          label="Email"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="auth.email"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Password"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="auth.password"
            type="password"
            placeholder="Enter password"
            required
          ></b-form-input>
        </b-form-group>

        <div class="d-flex align-items-center justify-content-between">
          <b-button
            type="submit"
            variant="primary"
          >
            Войти
          </b-button>
          <span
            v-if="error"
            class="text-danger"
          >
            {{ error }}
          </span>
        </div>
      </b-form>
    </b-modal>
  </b-navbar>
</template>

<script>
import ApiService from '@/common/api.service'

import { directive as onClickaway } from 'vue-clickaway'

import { mapGetters } from 'vuex'

export default {
  name: 'PageNavBar',
  directives: {
    onClickaway
  },
  data () {
    return {
      isOpenCurrencyDropdown: false,
      error: '',
      auth: {
        email: '',
        password: ''
      },
      coindesk: {
        date_start: '',
        date_end: ''
      },
      currency: ['USD', 'EUR', 'CNY', 'RUB']
    }
  },
  watch: {
    '$route' () {
      this.userUnauthorized()
    }
  },
  computed: {
    ...mapGetters({
      currentCurrency: 'cookie/getCurrency'
    }),
    filteredCurrency () {
      return this.currency.filter(currency => currency.toLowerCase() !== this.currentCurrency.toLowerCase())
    }
  },
  mounted () {
    this.userUnauthorized()
  },
  methods: {
    toggleCurrencyDropdown () {
      this.isOpenCurrencyDropdown = !this.isOpenCurrencyDropdown
    },
    closeCurrencyDropdown () {
      this.isOpenCurrencyDropdown = false
    },
    changeCurrency (currency) {
      this.$store.dispatch('cookie/changeCurrency', currency.toLowerCase())
    },
    userUnauthorized () {
      if (this.$route.params.unauthorized) {
        localStorage.removeItem('token_hashratecost')
        this.openModalAuth()
      }
    },
    onSubmit () {
      ApiService.post('auth/login', this.auth)
        .then((data) => {
          localStorage.setItem('token_hashratecost', data.headers.authorization)
          this.closeModalAuth()
          this.$router.push({ name: 'Admin' })
        })
        .catch((error) => {
          this.error = error.response.data.error
        })
    },
    onOpenAdminPanel () {
      if (localStorage.getItem('token_hashratecost')) {
        this.$router.push({ name: 'Admin' })
      } else {
        this.openModalAuth()
      }
    },
    openModalAuth () {
      this.$bvModal.show('bv-modal-auth')
    },
    closeModalAuth () {
      this.$bvModal.hide('bv-modal-auth')
    },
    logout () {
      ApiService.post('auth/logout')
      localStorage.removeItem('token_hashratecost')
      this.$router.push({ name: 'Home' })
    },
    openModalCoinDesk () {
      this.$bvModal.show('bv-modal-coindesk')
    },
    closeModalCoinDesk () {
      this.$bvModal.hide('bv-modal-coindesk')
    },
    onSubmitCoinDesk () {
      ApiService.get('admin_board/export/closing_prices', this.coindesk, 'blob')
        .then((req) => {
          const blob = new Blob([req.data], {
            type: req.headers['content-type']
          })

          const fileName = `coindesk_report_${new Date().toLocaleDateString()}`
          const fileType = 'xlsx'
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(blob)
          link.download = `${fileName}.${fileType}`
          link.click()

          this.closeModalCoinDesk()

          return req
        })
        .catch((error) => {
          this.error = error.response.data.error
        })
    }
  }
}
</script>

<style lang="scss">
  .header-lang {
    position: relative;

    &__dropdown {
      position: absolute;
      top: 100%;
      right: -5px;
      width: 80px;
      background: #fff;
      border-radius: 5px;
      padding: 0;
      box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.22);
    }

    &__dropdown-item {
      padding: 5px 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.5);

      &:hover {
        color: rgba(0, 0, 0, 0.9);
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
</style>
