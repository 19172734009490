const cookie = {
  namespaced: true,
  state: {
    currency: 'eur'
  },
  mutations: {
    CHANGE_CURRENCY (state, currency) {
      state.currency = currency
    },
    SET_CURRENCY (state, currency) {
      state.currency = currency
    }
  },
  actions: {
    changeCurrency ({ commit }, currency) {
      commit('CHANGE_CURRENCY', currency)
      localStorage.setItem('currency', currency)
    },
    setCurrency ({ commit }, currency) {
      commit('SET_CURRENCY', currency || 'eur')
    }
  },
  getters: {
    getCurrency: state => state.currency
  }
}

export default cookie
