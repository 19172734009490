import axios from 'axios'
import router from '@/router/index'

const global = axios.create({
  baseURL: 'https://core.hashratecost.com/api/'
})

global.interceptors.response.use((response) => {
  return response
},
error => {
  if (error.response.status === 403) {
    localStorage.removeItem('token_hashratecost')
    router.push({ name: 'Home', params: { unauthorized: true } })
  }

  return Promise.reject(error)
})

export default global
