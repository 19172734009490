<template>
  <div>
    <spinner v-if="isLoad"/>
    <div v-else class="block edit-chart">
      <edit-chart
        class="mb-2"
        :chart-data="chartData"
        @update="fetchData"
      />
    </div>
  </div>
</template>

<script>
import EditChart from '@/components/EditChartTemp'
import Spinner from '@/components/Spinner'
import ApiService from '@/common/api.service'

export default {
  name: 'Admin',
  components: {
    EditChart,
    Spinner
  },
  data () {
    return {
      isLoad: true,
      chartData: []
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      ApiService.get('admin_board/data')
        .then(({ data }) => {
          this.isLoad = false
          this.chartData = data
        })
        .catch(err => console.error(err))
    }
  }
}
</script>

<style lang="scss">
.edit-chart {
  max-width: 640px;
}
</style>
