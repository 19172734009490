<template>
  <div class="home">
    <spinner v-if="isLoad"/>
    <template v-else>
      <div class="home__charts-wrap">
        <div
          v-for="(chart, chartIndex) of chartsData"
          :key="chartIndex"
          class="block"
        >
          <area-chart :chart="chart" />
        </div>
      </div>

      <div v-if="tableData.length" class="block">
        <strong>Среднесуточные значения за последние 30 дней</strong>
        <b-table
          stacked="sm"
          striped small
          hover
          head-variant="dark"
          :caption-top="true"
          :fields="tableFields"
          :items="tableData"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import Spinner from '@/components/Spinner'
import AreaChart from '@/components/AreaChart'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    AreaChart,
    Spinner
  },
  data () {
    return {
      isLoad: true,
      tableData: [],
      chartsData: []
    }
  },
  computed: {
    tableFields () {
      return Object.keys(this.tableData[0])
    },
    ...mapGetters({
      currency: 'cookie/getCurrency'
    })
  },
  watch: {
    currency () {
      this.fetchData()
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      this.isLoad = true

      ApiService.get('data', { currency: this.currency })
        .then(({ data }) => {
          this.isLoad = false

          if (data.error) return

          this.chartsData = data.charts || []

          // Проходимся по данным, что бы получить все столбцы
          let tableColumns = new Set()

          Object.values(data.avg || {}).forEach(row => {
            Object.keys(row).forEach(key => tableColumns.add(key))
          })

          // Сортируем столбцы в нужном порядке
          tableColumns = Array.from(tableColumns).sort((a, b) => {
            const order = {
              DATE: 1,
              'I CAT': 2,
              'II CAT': 3,
              'III CAT': 4,
              'IV CAT': 5,
              'V CAT': 6,
              'VI CAT': 7
            }

            return (order[a] - order[b]) || 1
          })

          // Заполняем данные и пробелы, если имеются
          this.tableData = Object.values(data.avg || {}).map(row => {
            const rowResult = {}

            for (const key of tableColumns) {
              rowResult[key] = row[key] || '-'
            }

            return { ...rowResult }
          })

          // Обновление через час + 1 минута
          setTimeout(this.fetchData, 3600000 + 60000)
        })
    }
  }
}
</script>

<style lang="scss">
.home {
  &__charts-wrap {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-gap: 1rem;
    margin-bottom: 2rem;

    @media (max-width: 1450px) {
      grid-template-columns: repeat(2, minmax(100px, 1fr));
    }

    @media (max-width: 950px) {
      grid-template-columns: repeat(1, minmax(100px, 1fr));
    }

    @media (max-width: 500px) {
      .apexcharts-toolbar {
        display: none;
      }
    }
  }
}
</style>
